import { Drawer, Grid, Menu, MenuProps } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const { useBreakpoint } = Grid

const items: MenuProps['items'] = [
  {
    label: 'Ubicación',
    key: 'ubicacion',
    children: [
      {
        label: <Link to={'/ubicacion/mapa'}>Mapa de ubicación</Link>,
        key: 'mapa',
      },
      {
        label: (
          <Link to={'/ubicacion/altos-guayacanes'}>
            <span style={{ lineHeight: 1.5, display: 'block' }}>
              Condominio de Playa
              <br />
              Altos de Guayacanes
            </span>
          </Link>
        ),
        key: 'altos-guayacanes',
      },
    ],
  },
  {
    label: 'Proyecto',
    key: 'proyecto',
    children: [
      {
        label: (
          <Link to="/proyecto/canoes-camino-mar">Canoes, Camino al Mar</Link>
        ),
        key: 'canoes-camino-mar',
      },
      {
        label: <Link to="/proyecto/master-plan">Master Plan</Link>,
        key: 'master-plan',
      },
      {
        label: <Link to="/proyecto/galeria">Galería</Link>,
        key: 'proyecto-galeria',
      },
      {
        label: <Link to="/proyecto/video">Video</Link>,
        key: 'video',
      },
    ],
  },
  {
    label: 'Casas',
    key: 'casas',
    children: [
      {
        label: <Link to="/casas/descripcion">Descripción</Link>,
        key: 'descripcion',
      },
      {
        label: <Link to="/casas/planos">Planos</Link>,
        key: 'planos',
      },
      {
        label: <Link to="/casas/galeria">Galería</Link>,
        key: 'casas-galeria',
      },
      {
        label: <Link to="/casas/360">Recorrido 360</Link>,
        key: '360',
      },
    ],
  },
  {
    label: <Link to="desarrolladores-aliados">Desarrolladores y Aliados</Link>,
    key: 'desarrolladores-aliados',
  },
  {
    label: <Link to="contactanos">Contáctanos</Link>,
    key: 'contactanos',
  },
  {
    label: <Link to="noticias">Noticias</Link>,
    key: 'noticias',
  },
]

interface NavMenuProps {
  onClose: () => void
  open: boolean
}
const NavMenu: React.FC<NavMenuProps> = ({ onClose, open }) => {
  const location = useLocation()

  const [current, setCurrent] = useState<string>()

  useEffect(() => {
    if (location.pathname) {
      const partesUrl = location.pathname.split('/')
      onClose()
      setCurrent(
        partesUrl[partesUrl.length - 1] !== 'galeria'
          ? partesUrl[partesUrl.length - 1]
          : partesUrl[partesUrl.length - 2] +
              '-' +
              partesUrl[partesUrl.length - 1],
      )
    }
  }, [location.pathname])

  return (
    <>
      <ContainerDesktop>
        <Container>
          <MenuStyled
            selectedKeys={[current || '']}
            mode="horizontal"
            items={items}
          />
          <Button href="tel:+573175955706">Contacta un asesor</Button>
        </Container>
      </ContainerDesktop>
      <ContainerMobile>
        <Drawer
          placement="right"
          onClose={onClose}
          open={open}
          style={{ background: '#1b2241ec' }}
          size="default"
        >
          <MenuStyled
            style={{ minWidth: 0 }}
            selectedKeys={[current || '']}
            mode="inline"
            items={[
              ...items,
              {
                label: (
                  <Button
                    style={{ width: 'calc(100% - 48px)', display: 'block' }}
                    href="tel:+573175955706"
                  >
                    Contacta un asesor
                  </Button>
                ),
                key: 'call',
              },
            ]}
          />
        </Drawer>
      </ContainerMobile>
    </>
  )
}
export default NavMenu

const ContainerMobile = styled.div`
  display: none;
  @media (width <= 1029px) {
    display: initial;
  }
`
const ContainerDesktop = styled.div`
  display: initial;
  @media (width <= 1029px) {
    display: none;
  }
`

const Button = styled.a`
  background-color: #fff !important;
  padding: 10px 15px;
  border-radius: 5px !important;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;
  color: #1b2241 !important;
  &:hover {
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
`

const MenuStyled = styled(Menu)`
  min-width: 636px;
  height: 60px;
  background-color: transparent;
  color: #fff;
  font-family: 'Roboto', sans-serif;

  & .ant-menu-submenu-title {
    height: 60px;
  }

  & .ant-menu-title-content {
    line-height: 60px;
  }
`
